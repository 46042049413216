<template>
  <div class="addressDialog">
    <el-dialog top="3%" width="500px" :title="dialogTitle" :visible.sync="visible" :close-on-click-modal="false"
      :before-close="closeEvent">
      <el-form ref="dialogForm" :rules="rules" :model="form" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="分类名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入分类名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否启用">
              <el-radio-group v-model="form.type">
                <el-radio label="0">否</el-radio>
                <el-radio label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  data() {
    return {
      pcaTextArr,
      visible: false,
      dialogTitle: "新增分类",
      userList: [],
      form: {
        name: "",
        type: '0',//0未启用，1启用
      },
      rules: {
        name: [
          { required: true, message: "请输入店铺名称", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let params = { ...this.form };
          let url = "";
          if (params.id) {
            //修改
            url = "api/classify/update";
          } else {
            url = "api/classify/add";
          }

          this.getAjax(url, params, "post").then(() => {
            this.$message.success("操作成功");
            this.closeEvent();
            this.$emit("search");
          });
        }
      });
    },
    closeEvent() {
      this.$refs.dialogForm && this.$refs.dialogForm.resetFields();
      this.visible = false;
      this.dialogTitle = "新增分类";
      this.form = {};
    },
    getInfo(item) {
      this.dialogTitle = "编辑分类";
      this.form = JSON.parse(JSON.stringify(item));
      this.visible = true;
    },
  },
  mounted() {
    //获取所有用户
    this.getAjax("api/allUser", {}, "get").then((res) => {
      this.userList = res.data.list.map((e) => {
        return {
          label: e.realName,
          value: e.id,
        };
      });
    });
  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

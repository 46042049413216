<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        border style="width: 100%">
        <el-table-column type="index" :index="indexMethod" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="id" label="分类ID" textAlign="left" width="80"></el-table-column>
        <el-table-column prop="name" label="分类名称" textAlign="left"></el-table-column>
        <el-table-column prop label="是否启用">
          <template slot-scope="scope">
            <span :class="scope.row.type === '0' ? 'c_red' : 'c_green'">{{ scope.row.type === '0' ? '未启用' : '已启用'
              }}</span>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="200">

          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editData(scope.row)">编辑</el-button>
            <el-button @click="changeType(scope.row, '1')" v-if="scope.row.type === '0'" type="text"
              size="small">启用</el-button>
            <el-button @click="changeType(scope.row, '0')" v-else type="text" size="small">停用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
    <SelfPointDialog ref="dialog" @search="initPage" />
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
import SelfPointDialog from "./components/dialog.vue";
export default {
  components: { searchBar, SelfPointDialog },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {
        name: ''
      },
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "name",
          attrs: {
            placeholder: "分类名称",
          },
        },
      ],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/classify/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item);
    },
    changeType(item, type) {
      this.getAjax("api/classify/changeType", { id: item.id, type }, "post").then(
        () => {
          this.$message.success("操作成功");
          this.initPage();
        }
      );
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;

  .leftBox {
    width: 10%;
    min-width: 130px;
    height: 100%;
    background: #545c64;
  }

  .rightBox {
    flex: 1;
    padding: 20px;

    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
